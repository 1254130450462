
@import "../../assets/style/iconfont.css";
.c-input-width-80 {
    width: 80px;
}
.tooltipTxt { color: #909399 }
.addCourseForm {
    .course-type-radio {
        .el-form-item__label { width: 100%; }
    }
}
.create-course-dialog {
    .el-dialog {
        .el-dialog__header {
            border-bottom: 1px solid #e1e1e1;
            padding: 20px 50px;
        }
        .el-dialog__body {
            padding: 30px 50px;
        }
    }
}
.container {
    margin: auto;
    min-height: 400px;
    padding-left: 10px;
    padding-right: 10px;
    .row {
        margin-left: -10px;
        margin-right: -10px;
        .panel {
            margin-bottom: 20px;
            background-color: #fff;
            border-radius: 4px;
            .el-form {
                .el-form-item:first-child {
                    .el-form-item__label {
                    }
                    .el-radio-group {
                        width: 100%;
                        .el-radio-button {
                            position: relative;
                            min-height: 1px;

                            border: 2px solid transparent;
                            border-radius: 10px;
                            .el-radio-button__inner {
                                padding: 35px 15px;
                                width: 100%;
                                height: 250px;
                                border: 2px solid transparent;
                                text-align: center;
                                background-color: #f4f6f8;
                                border-radius: 10px;
                                cursor: pointer;
                                &:hover {
                                    color: #616161;
                                }
                                i {
                                    font-size: 47px;
                                }
                                .course-type {
                                    padding: 20px 0;
                                    font-size: 16px;
                                    border-bottom: 1px solid #e1e1e1;
                                }
                                .course-intro {
                                    padding-top: 20px;
                                    font-size: 12px;
                                    white-space: initial;
                                    line-height: 1.42857143;
                                }
                            }
                            &:hover {
                                border: 2px solid #409EFF;
                            }
                        }
                        .is-disabled {
                            &:hover {
                                border: 2px solid transparent !important;
                            }
                            .el-radio-button__inner {
                                &:hover {
                                    color: #C0C4CC !important;
                                    cursor: not-allowed;
                                }
                            }
                        }
                        .el-radio-button:last-child {
                            margin-right: 0;
                        }
                        .is-active {
                            &:hover {
                                border: 2px solid transparent;
                            }
                            .el-radio-button__inner {
                                width: 100%;
                                background-color: #409EFF;
                                color: #fff;
                                &:hover {
                                    color: #fff;
                                }
                                .course-type {
                                    border-bottom: 1px solid #fff;
                                }
                            }
                        }
                    }
                }
                .el-form-item {
                    .el-form-item__label {
                        position: relative;
                        text-align: left;
                        padding-left: 15px;
                        font-size: 16px;
                        line-height: 22px;
                        margin-right: 20px;
                        &:before {
                            content: "";
                            position: absolute;
                            left: 0;
                            top: 3px;
                            width: 4px;
                            height: 16px;
                            background-color: #409EFF;
                        }
                    }
                }
            }
            .other-item {
                display: flex;
                align-items: center;
                .el-form-item__content {
                    flex: 1;
                    textarea {
                        height: 114px;
                        resize: none;
                    }
                    .cover-uploader {
                        .el-upload {
                            display: flex;
                            align-items: center;
                            .el-button {
                                margin-right: 20px;
                            }
                            .cover {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 178px;
                                height: 100px;
                                border: 1px solid #DCDFE6;
                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
        .panel-default {
            border-color: #e4ecf3;
            min-height: 500px;
        }
    }
}

.item-txt-tip {
    color: #F56C6C;
    margin-left: 10px;
    font-size: 12px;
}

@media (min-width: 768px) {
    .create-course-dialog {
        .el-dialog {
            width: 740px;
        }
    }
}

@media (min-width: 992px) {
    .container .row .panel .el-form .el-form-item:first-child .el-radio-group .el-radio-button {
        margin: 20px 16px 30px 0;
    }
    .create-course-dialog {
        .el-dialog {
            width: 960px;
            .el-radio-button {
                width: 23%;
                float: left;
            }
        }
    }
}

@media (min-width: 1200px) {
    .create-course-dialog {
        .el-dialog {
            width: 1160px;
        }
    }
    .container .row .panel .el-form .el-form-item:first-child .el-radio-group .el-radio-button {
        margin: 20px 22px 30px 0;
    }
}
